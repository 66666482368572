@import url('https://fonts.googleapis.com/css2?family=Asset&family=Roboto+Flex:opsz,wght@8..144,100;8..144,300;8..144,500;8..144,600&display=swap');

.links-top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    position: fixed;
    z-index: 999;
    width: 100vw;
    top: 0;
}

.links-bottom {
    display: flex;
    justify-content: space-between;
    position: fixed;
    z-index: 99;
    bottom: 0;
    width: 100vw;
}

.name-link {
    display: block;
    font-size: 80px;
    text-decoration: none;
    color: rgb(30, 30, 30);
    margin-left: 10px;

    @media (max-width: 1025px) {
     font-family: 'Roboto Flex', sans-serif;
     font-size: 60px;   
    }
}

.works-link {
    display: block;
    font-size: 60px;
    text-decoration: none;
    color: rgb(30, 30, 30);
    margin-right: 10px;

    @media (max-width: 1025px) {
        font-family: 'Roboto Flex', sans-serif;
        font-size: 40px;   
       }
}

.cv-link {
    display: block;
    font-size: 60px;
    text-decoration: none;
    color: rgb(30, 30, 30);
    margin-left: 10px;

    @media (max-width: 1025px) {
        font-family: 'Roboto Flex', sans-serif;
        font-size: 40px;   
       }
}

.contact-link {
    display: block;
    font-size: 60px;
    text-decoration: none;
    color: rgb(30, 30, 30);
    margin-right: 10px;

    @media (max-width: 1025px) {
        font-family: 'Roboto Flex', sans-serif;
        font-size: 40px;   
       }
}