@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

@font-face {
    font-family: 'LeagueSpartan-Bold';
    src: url('../../assets/font/LeagueSpartan-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.container {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    height:100vh;
}

.contact-form {

    @media (max-width: 1025px) {
        width: 300px;
    }

    @media (min-width:1025px) {
        margin-right: 5em;
        margin-left: 7em;
        width: 700px;
    }



    ul {
        padding: 0;
        margin: 0;

        li {
            padding: 0;
            margin: 0;
            list-style: none;
            margin-bottom: 10px;
            overflow: hidden;
            display: block;
            position: relative;
            opacity: 0;
            animation: fadeInDown 1s 0.5s;
            animation-fill-mode: forwards;
            clear: both;
        }


    }

    input[type='text'],
    input[type='email'] {
        width: 100%;
        border: 0;
        background: transparent;
        border-bottom: 1px solid black;
        height: 50px;
        font-size: 16px;
        padding: 0 20px;
        box-sizing: border-box;
        -webkit-border-radius:0; 
        border-radius:0;
        @media (max-width: 1025px) {
            height: 30px;
        }
    }

    input:focus::placeholder {
        color: transparent;
       }
      
    input::placeholder {
        color: #494f59;
        transition: color 0.3s ease;
       } 

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
       transition: background-color 5000s ease-in-out 0s;
    }

    input:focus, textarea:focus, select:focus{
        outline: none;
    }

    textarea {
        font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
        font-size: 16px;
        font-weight: 400;
        width: 100%;
        border: none;
        background: transparent;
        border-bottom: 1px solid black;
        height: 50px;
        padding: 12px 20px;
        min-height: 50px;
        box-sizing: border-box;
        resize: none;
        overflow: hidden;
        @media (max-width: 1025px) {
            height: 30px;
            min-height: 30px;
            padding-top: 5px;
            padding-bottom: 20px;
            font-family: 'Roboto', sans-serif;
        }
    }

    textarea:focus::placeholder {
        color: transparent;
       }
      
    textarea::placeholder {
        color: #494f59;
        transition: color 0.3s ease;
       }

    .button {
        font-size: 11px;
        letter-spacing: 3px;
        text-decoration: none;
        padding: 10px 13px;
        border-radius: 25px;
        border: none;
        color: #5e4c49;
        float: right;
        cursor: pointer;
        transition: all .3s;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
    }

    .button:hover {
        background-color: #5e4c49;
        color: #ffff;
    }

}

.contact-h5 {
    font-family: 'LeagueSpartan-Bold';
    -webkit-text-stroke: 2px black;
    color: #f4f0f0;
    font-size: 65px;
    margin-bottom: 0; 
    @media (max-width: 1025px) {
        -webkit-text-stroke: 1px black;
        font-size: 41px;
        margin-top: 0;
    }
}

.contact-h6 {
    margin-top: 15px;
    font-size: 12px;
}

.contact-text {
    animation: fadeInDown 1s 0.5s both;
}

.c-link {
    text-decoration: none;
    color: black;
    border-bottom: 0.8px solid black;
}

.status-message {
    color: #f0745e;
    margin-left: 0;
    margin-top: 0;
    font-size: 16px;
}

.smile {
    color: #f0745e;
    margin-left: 0;
    margin-top: 0;
    font-size: 24px;
}

.contact-pic {

    @media (max-width: 1025px) {
        display: none;
    }

    @media (min-width:1025px) {
        object-fit: cover;
        margin-right: 5em;
        width: 400px;
        height: 600px;
        animation: fadeInRight 1s 0.5s both;
    }
    
}