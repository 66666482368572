@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.cv {
    width: 100vw;
    text-align: justify;
    text-justify: inter-word;
    display: flex;
    flex-direction: column;
    align-items: center;
    @media (max-width: 1025px) {
        text-align: left;
        font-family: 'Roboto', sans-serif;
    }
}


.about {
    width: 914px;
    text-align: justify;
    text-justify: inter-word;
    @media (max-width: 1025px) {
        width: 300px;
    }
}

.cv-section {
    width: 914px;
    padding-top: 7%;
    padding-bottom: 7%;
    animation: fadeInDown 1s 0.5s both;

    @media (max-width: 1025px) {
        width: 300px;
        padding-top: 40%;
        padding-bottom: 30%;
    }
}

.ig-link {
    padding-top: 3px;
}

.arrow {
    width: 46px;
    margin-top: 30px;
    margin-left: 40%;
    cursor: pointer;
    opacity: 0.5;
    transition: all .5s ease-in-out;
}

.arrow:hover {
    animation: pulse;
    animation-duration: 1s;
    animation-iteration-count: infinite;
    opacity: 1;
}

.grants {
    margin-bottom: 10em;
}

.cv-name {
    font-size: 16px;
}

.cv-category-title {
    font-size: 13px;
    margin-top: 32px;
}

.cv-section h4 {
    font-size: 16px;
    margin-top: 0;
    margin-bottom: 10;
}

.cv-section h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    font-weight: 400;
}

.cv-section p {
    font-size: 12px;
    margin-left: 50px;
    margin-right: 30px;
}

b {
    color: rgb(101, 101, 101);
    padding: 5px 7px;
    font-size: 13px;
    margin-left: -46px;
}

.a-link {
    text-decoration: none;
    font-size: 10px;
    color: rgb(101, 101, 101);
}

strong {
    color: rgb(101, 101, 101);
}