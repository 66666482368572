/*@import url('https://fonts.googleapis.com/css2?family=Asset&family=Roboto:wght@700&display=swap');*/

@font-face {
    font-family: 'LeagueSpartan-Bold';
    src: url('../../assets/font/LeagueSpartan-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.menu {
    margin-left: 4em;
    margin-right: 4em;
    padding-top: 6%;
    padding-bottom: 6%;

    @media (max-width: 1025px) {
        padding-top: 40%;
        padding-bottom: 60%;
        margin-left: 3em;   
       }
}

.menu__item-img {
	pointer-events: none;
	position: fixed;
	height: 50vh;
	max-height: 400px;
	opacity: 0;
	left: 100%;
	top: 30%;
	transform: translate3d(calc(-100% - 6vw),-30%,0) translate3d(0,20px,0);
    z-index: 0;
}

.menu__item-link {
    font-family: 'LeagueSpartan-Bold';
    -webkit-text-stroke: 2px #5e4c49;
    color: #f4f0f0;
    font-size: 35px;
    font-weight: 700;
    opacity: 0;
    animation: fadeInDown 2s 0.5s;
    animation-fill-mode: forwards;
    z-index: 7;
    position: relative;

    @media (max-width: 1025px) {
        -webkit-text-stroke: 1px black;
        font-size: 21px;   
       }
}

.menu__item-link:hover {
    font-family: 'LeagueSpartan-Bold';
    -webkit-text-stroke: 2px #5e4c49;
    color: #5e4c49;
    font-size: 35px;
    font-weight: 700;
    @media (max-width: 1025px) {
        -webkit-text-stroke: 1px black;
        color: #f4f0f0;
        font-size: 23px;    
    }
   

    .menu__item-img {
        opacity: 1;
        transform: translate3d(calc(-100% - 6vw),-30%,0) rotate3d(0,0,1,4deg);
        transition: all 0.4s;
        @media (max-width: 1025px) {
            display: none;   
        }
    }
}





