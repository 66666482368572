.homepage-background {
    height:100vh;
    width:100vw;
    background-image: url("../../images/background5.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    @media (max-width: 1025px) {
        background-image: url("../../images/background.jpg");
    }
}
