@font-face {
    font-family: 'LeagueSpartan-Bold';
    src: url('../../assets/font/LeagueSpartan-Bold.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

.error-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 20%;
}

.error-title {
    font-family: 'LeagueSpartan-Bold';
    -webkit-text-stroke: 2px black;
    color: #f4f0f0;
    font-size: 55px;
    font-weight: 700;
    margin-bottom: 0;
    opacity: 0;
    animation: fadeInDown 2s 0.5s;
    animation-fill-mode: forwards;
}

.error-description {
    font-size: 20px;
    margin-top: 0;
    opacity: 0;
    animation: fadeInDown 2s 0.5s;
    animation-fill-mode: forwards;
}

.error-button {
    padding: 30px;
    border-radius: 25px;
    border: none;
    font-weight: 700;
    font-size: 15px;
    cursor: pointer;
    color: #5e4c49;
    opacity: 0;
    animation: fadeInDown 2s 0.5s;
    animation-fill-mode: forwards;
}

.error-button:hover {
    background-color: #5e4c49;
    color: white;
}