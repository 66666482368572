.work__detail {
    margin-top: 8em; 
    animation: fadeInDown 2s 0.5s both;
}

.work__card {
    display: flex;
    align-items: center;
    max-width: 350px;
    max-height: 400px;
    text-align: justify;
    text-justify: inter-word;
    margin-right: 150px;
    margin-bottom: 300px;
    
    @media (max-width: 1025px) {
        max-width: 320px;
        margin-left: 10em;
        margin-bottom: 60px;
    }
}

.work__info-card {
    width: 350px;
}

.work__detail-title {
    font-size: 25px;
    font-weight: 400;
    text-align: left;
    @media (max-width: 1025px) {
        font-size: 20px;
    }
}

.work__card p {
    font-size: 12px;
}

.work__detail-basics {
    color: rgb(101, 101, 101);
    font-size: 10px;
}

.work__detail-credits {
    color: rgb(101, 101, 101);
    font-size: 10px;
}

.rhap_container.rhap_loop--off.rhap_play-status--paused  {
    background-color: transparent;
    box-shadow: none;
}

.rhap_container.rhap_loop--off.rhap_play-status--playing  {
    background-color: transparent;
    box-shadow: none;
}

.rhap_time.rhap_current-time, .rhap_time.rhap_total-time {
    font-size: 10px;
    padding-right: 6px;
    @media (max-width: 1025px) {
        padding-right: 20px;
    }
}

.rhap_button-clear.rhap_main-controls-button.rhap_play-pause-button {
    font-size: 25px;
    padding-top: 5px;
}

.rhap_progress-bar.rhap_progress-bar-show-download {
    @media (min-width: 1025px) {
        width: 200px;
    }
}

.grid {
    display: grid; 
    grid-template-columns: 1fr 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr; 
    gap: 0px 0px; 
    grid-template-areas: 
      "div1 div2 div3"
      "div4 . div3"
      "div4 div5 div6"
      "div7 . div6"
      ". div8 ."; 
    align-content: stretch; 
    justify-items: center; 
    align-items: center; 
  }
  .div1 { grid-area: div1; }
  .div2 { grid-area: div2; }
  .div3 { grid-area: div3; }
  .div4 { grid-area: div4; }
  .div5 { grid-area: div5; }
  .div6 { grid-area: 2 / 2 / 3 / 4; }
  .div7 { grid-area: 4 / 1 / 5 / 3; }
  .div8 { grid-area: div8; }

  @media (max-width: 1025px) {

    .grid {
        display: flex;
        flex-direction: column;
        padding-top: 10%;
        padding-bottom: 20%;
    }

    .grid :nth-child(1) {order: 2;}
    .grid :nth-child(2) {order: 1;}
    .grid :nth-child(3) {order: 3;}
    .grid :nth-child(4) {order: 4;}
    .grid :nth-child(5) {order: 5;}
    .grid :nth-child(6) {order: 6;}
    .grid :nth-child(7) {order: 7;}
    .grid :nth-child(8) {order: 8;}

}

.img1 {
    max-width: 550px;
    @media (max-width: 1025px) {
        max-width: 360px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
}

.img2 {
    max-width: 600px;
    @media (min-width: 1025px) {
        margin-bottom: 500px;
    }
    @media (max-width: 1025px) {
        max-width: 360px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
} 

.img3 {
    max-width: 550px;
    @media (max-width: 1025px) {
        max-width: 360px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
}

.img4 {
    max-width: 450px;
    @media (max-width: 1025px) {
        max-width: 360px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
}

.img5 {
    max-width: 850px;
    @media (max-width: 1025px) {
        max-width: 360px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
}

.img6 {
    max-width: 850px;
    max-height: 600px;
    @media (max-width: 1025px) {
        max-width: 360px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
}

.img7 {
    max-width: 550px;
    @media (max-width: 1025px) {
        max-width: 360px;
        margin-bottom: 10px;
        margin-top: 10px;
    }
}
